import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  span {
    font-weight: 500;
    color: #666;
  }
`;

export const Address = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  span {
    font-size: 0.8rem;
    font-weight: 300;
    color: #666;
  }
`;
