import React from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { orderAddItem } from '../../store/modules/order/actions';

import { Container } from './styles';

function AddToCart({ order }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const handleClick = () => {
    order.items.forEach(item => {
      dispatch(orderAddItem(item));
    });

    enqueueSnackbar('Pedido adicionado ao carrinho', { variant: 'success' });
  };

  return (
    order.status === 'concluded' && (
      <Container type="button" onClick={() => handleClick()}>
        <span>Adicionar ao carrinho</span>
      </Container>
    )
  );
}

export { AddToCart };
