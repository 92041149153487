import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff',
  },
}));

export default useStyles;
