import axios from 'axios';
import { urlBase, userCredentials } from './base';

const sendMessage = async (company, order, message) => {
  const token = 'lyk4eqpfut4dywus';
  const phone = '556593506792';
  const body = JSON.stringify({ company, order, message });

  const url = `https://api.1msg.io/77460/sendMessage?token=${token}`;

  await axios.post(url, { phone, body });
};

export const index = async () => {
  const { token } = userCredentials();

  const url = `${urlBase}?t=${token}`;

  const response = await axios.get(url);

  return response.data;
};

export const orderset = async order => {
  const { token } = userCredentials();

  const url = `${urlBase}/orderset/?t=${token}`;

  try {
    const response = await axios.post(url, order);
    return response.data;
  } catch (error) {
    sendMessage(token, order, error.response.data);
    throw new Error(
      'Falha ao tentar enviar seu pedido. Verifique sua conexão com a internet'
    );
  }
};

export const ordersstatus = async orders => {
  const { token } = userCredentials();

  const url = `${urlBase}/ordersstatus/?t=${token}`;

  const response = await axios.post(url, orders);

  return response.data;
};

export const pix = async order => {
  const { token } = userCredentials();

  const url = `${urlBase}/pix/${order}/?t=${token}`;

  const response = await axios.get(url);

  return response.data;
};
