export const ActionTypes = Object.freeze({
  LOAD: '@data/LOAD',
  SET_CLIENT: '@data/SET_CLIENT',
  LOADING: '@data/LOADING',
  MENU: '@data/MENU',
});

export function dataLoad({
  token,
  company,
  categories,
  kms,
  districts,
  cards,
}) {
  return {
    type: ActionTypes.LOAD,
    payload: { token, company, categories, kms, districts, cards },
  };
}

export function dataSetClient({ name, phone }) {
  return {
    type: ActionTypes.SET_CLIENT,
    payload: { client: { name, phone } },
  };
}

export function dataLoading() {
  return {
    type: ActionTypes.LOADING,
  };
}

export function dataMenu() {
  return {
    type: ActionTypes.MENU,
  };
}
