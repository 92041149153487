import React from 'react';
import { Drawer, Button } from '@material-ui/core';

import PixImage from '../../../assets/pix-logo.png';

import useStyles from './styles';

function Steps({ open, onClose }) {
  const classes = useStyles();

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.logo}>
          <img src={PixImage} alt="Pix" />
        </div>

        <span>Como pagar pedidos com Pix</span>

        <div className={classes.step}>
          <span>Copie o código que foi gerado</span>
          <span>1° passo</span>
        </div>

        <div className={classes.step}>
          <span>
            Abra um aplicativo em que voce tenha o Pix habilitado e use a opção
            Pix Copia e Cola
          </span>
          <span>2° passo</span>
        </div>

        <div className={classes.step}>
          <span>
            Cole o código, confirme o valor e faça o pagamento. Ele será
            confirmado na hora :)
          </span>
          <span>3° passo</span>
        </div>

        <Button
          color="secondary"
          variant="contained"
          className={classes.button}
          onClick={onClose}
        >
          Ok, entendi
        </Button>
      </div>
    </Drawer>
  );
}

export { Steps };
