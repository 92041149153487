import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 15px',
    backgroundColor: '#fafafa',
    borderTop: '1px solid rgba(149, 149, 149, 0.1)',
  },
  title: {
    height: '4rem',
    textTransform: 'capitalize',
    fontSize: '0.975rem',
    fontWeight: '500',
    padding: '20px 0px',
  },
});

export default useStyles;
