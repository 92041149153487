import React from 'react';
import { useSelector } from 'react-redux';
import { formatPrice } from '../../../utils/format';
import useStyles from './styles';

function Totals() {
  const classes = useStyles();

  const { formattedSubtotal, formattedDelivery, formattedTotal } = useSelector(
    state => {
      const { order } = state.order;

      return {
        formattedSubtotal: formatPrice(order.subtotal),
        formattedDelivery: formatPrice(order.delivery),
        formattedTotal: formatPrice(order.total),
      };
    }
  );

  return (
    <ul className={classes.root}>
      <li className={classes.subtotal}>
        <span>Subtotal</span>
        <span>{formattedSubtotal}</span>
      </li>
      <li className={classes.delivery}>
        <span>Taxa de entrega</span>
        <span>{formattedDelivery}</span>
      </li>
      <li className={classes.total}>
        <span>Total</span>
        <span>{formattedTotal}</span>
      </li>
    </ul>
  );
}

export default Totals;
