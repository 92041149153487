import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';

import { useSnackbar } from 'notistack';
import { formatPrice } from '../../../utils/format';
import { orderCheckout } from '../../../store/modules/order/actions';

import useStyles from './styles';

function Send() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { company, loading, client } = useSelector(state => state.data);
  const { order: current } = useSelector(state => state.order);

  const handleConfirm = useCallback(
    async (com, cli, cur, load) => {
      if (load) return;

      if (com.somenteagendamento === 'S' && !cur.scheduling) {
        enqueueSnackbar(
          'Por favor, agende uma data e hora de entrega/retirada',
          {
            variant: 'error',
          }
        );

        return;
      }

      if (com.enabled !== 1 && !cur.scheduling) {
        enqueueSnackbar('Indisponível no momento', { variant: 'error' });

        return;
      }

      if (cur.modality !== 0 && cur.modality !== 1) {
        enqueueSnackbar('Entrega ou Retirada no local?', {
          variant: 'error',
        });

        return;
      }

      if (cur.modality === 0 && cur.subtotal < com.minimum_value) {
        const formattedMinimumValue = formatPrice(com.minimum_value);
        enqueueSnackbar(
          `O valor minimo para entrega é de ${formattedMinimumValue}`,
          { variant: 'error' }
        );

        return;
      }

      if (cur.scheduling && cur.day.length === 0) {
        enqueueSnackbar('Selecione o dia para agendar o pedido', {
          variant: 'error',
        });

        return;
      }

      if (cur.scheduling && cur.hour.length === 0) {
        enqueueSnackbar('Selecione a hora para agendar o pedido', {
          variant: 'error',
        });

        return;
      }

      if (cur.modality === 0 && !cur.address) {
        enqueueSnackbar('Selecione um endereço para entrega', {
          variant: 'error',
        });

        return;
      }

      if (cur.payment.id === 0) {
        enqueueSnackbar('Selecione a forma de pagamento', { variant: 'error' });

        return;
      }

      if (cli.name.length === 0) {
        enqueueSnackbar('Qual o seu nome?', { variant: 'error' });

        return;
      }

      if (cli.phone.length <= 9) {
        enqueueSnackbar('Seu telefone deve ser preenchido corretamente', {
          variant: 'error',
        });

        return;
      }

      dispatch(orderCheckout());
    },
    [dispatch, enqueueSnackbar]
  );

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => handleConfirm(company, client, current, loading)}
      >
        pedir
      </Button>
    </div>
  );
}

export default Send;
