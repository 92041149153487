/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RoomIcon from '@material-ui/icons/Room';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import useStyles from './styles';
import { addressesModal } from '../../../store/modules/addresses/actions';

function Address() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { address, modality } = useSelector(state => state.order.order);

  function handleAdresses() {
    dispatch(addressesModal());
  }

  return modality === 0 ? (
    <div className={classes.root}>
      <span className={classes.title}>Qual seu endereço?</span>
      {address ? (
        <div className={clsx(classes.body)} onClick={handleAdresses}>
          <RoomIcon />
          <ul className={classes.fields}>
            <li className={classes.name}>
              <strong>{address.name}</strong>
            </li>
            <li>
              {` ${address.zip_code}`}
              {` ${address.public_place}`}
              {` ${address.number},`}
              {` ${address.state}`}
              {` ${address.city}`}
              {` ${address.neighborhood}`}
            </li>
          </ul>
          <EditIcon />
        </div>
      ) : (
        <button
          type="button"
          className={classes.button}
          onClick={handleAdresses}
        >
          <RoomIcon />
          <span>Selecione um endereço para entrega</span>
        </button>
      )}
    </div>
  ) : null;
}

export default Address;
