import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    border: '2px solid rgba(0,0,0,0.2)',
    borderRadius: '4px',
    height: '160px',
  },
}));

export default useStyles;
