import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '0 1rem',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    minHeight: '75px',
    cursor: 'pointer',
    border: 'none',
    padding: '1rem',
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: '1px solid rgba(149, 149, 149, 0.1)',
  },
  addresses: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    backgroundColor: '#eee',
    padding: '1rem',
  },
  addressButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    minHeight: '100px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#000',
    border: 'none',
    borderRadius: '8px',
    padding: '1rem',
  },
  addressButtonInfo: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: '.25rem',

    '& span': {
      textAlign: 'left',
    },

    '& :first-child ': {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
  selected: {
    border: '2px solid #FF9000',

    '& svg': {
      color: '#FF9000',
    },
  },
}));

export default useStyles;
