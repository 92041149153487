import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff5131',
    color: '#fff',
  },
  text: {
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    fontWeight: '500',
  },
}));

export default useStyles;
