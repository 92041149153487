import React from 'react';

import { Container } from './styles';

function Items({ order }) {
  const { items } = order;
  const { amount, name } = items[0];
  const nameFormated = name.toLowerCase();

  return (
    <Container>
      <span>
        <strong>{amount}</strong> {nameFormated}
      </span>

      {items.length > 1 && items.length === 2 && (
        <span>mais {items.length - 1} item</span>
      )}

      {items.length > 1 && items.length > 2 && (
        <span>mais {items.length - 1} itens</span>
      )}
    </Container>
  );
}

export { Items };
