import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    background: '#eee',
    padding: '15px',
    borderTop: '1px solid rgba(149, 149, 149, 0.4)',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
  },
  headerName: {
    textTransform: 'capitalize',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '5px',
  },
  span: {
    fontSize: '0.80rem',
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  list: {
    padding: '0 15px',
  },
});

export default useStyles;
