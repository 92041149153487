import { combineReducers } from 'redux';

import addresses from './addresses/reducer';
import data from './data/reducer';
import item from './item/reducer';
import order from './order/reducer';
import payments from './payments/reducer';

export default combineReducers({
  addresses,
  data,
  item,
  order,
  payments,
});
