import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    height: '50px',
    display: 'flex',
    borderRadius: '.5rem',
    backgroundColor: '#F97F11',
    color: '#fff',

    '&:hover': {
      backgroundColor: '#F97F11',
      opacity: 0.9,
    },
  },
  addresses: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    backgroundColor: '#eee',
    padding: '1rem',
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    padding: '1rem',
  },
}));

export default useStyles;
