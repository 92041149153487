import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
    padding: '15px',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
  },
  title: {
    marginBottom: '15px',
    fontWeight: 'bold',
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0px',
    padding: '0px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: '0px',
    padding: '0px 15px',
  },
  name: {
    marginBottom: '5px',
  },
  invalid: {
    color: 'red',
  },
  button: {
    height: '56px',
    padding: '1rem',
    background: '#eee',
    color: '#000',
    border: '1px solid rgba(149, 149, 149, 0.8)',
    borderRadius: '.5rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',

    '& span': {
      flex: '1',
      textAlign: 'center',
    },
  },
}));

export default useStyles;
