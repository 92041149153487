export const ActionTypes = Object.freeze({
  SELECTED: '@item/SELECTED',
  COMPLEMENT_ADD: '@item/COMPLEMENT_ADD',
  COMPLEMENT_REMOVE: '@item/COMPLEMENT_REMOVE',
  CANCEL: '@item/CANCEL',
});

export function itemSelected(item) {
  return {
    type: ActionTypes.SELECTED,
    payload: { item },
  };
}

export function itemComplementAdd(item) {
  return {
    type: ActionTypes.COMPLEMENT_ADD,
    payload: { item },
  };
}

export function itemComplementRemove(item) {
  return {
    type: ActionTypes.COMPLEMENT_REMOVE,
    payload: { item },
  };
}

export function itemCancel() {
  return {
    type: ActionTypes.CANCEL,
  };
}
