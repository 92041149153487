import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { debounce } from 'lodash';

import Category from './Category';
import useStyles from './styles';
import { itemSelected } from '../../../store/modules/item/actions';

export default function Body() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const valueDefault = 0;
  const [categoryId, setCategoryId] = useState(valueDefault);
  const { categories } = useSelector(state => state.data);

  function handleScroll() {
    const windowTop = window.pageYOffset;
    const items = document.querySelectorAll('[data-category]');
    items.forEach(item => {
      const top = windowTop;
      if (top >= item.offsetTop && top < item.offsetTop + item.clientHeight) {
        const category = Number(item.dataset.category);
        setCategoryId(category);
      }
    });
  }

  useEffect(() => {
    if (categories && categories.length >= 1) {
      const category = categories[0];
      setCategoryId(category.category_id);
    }

    window.addEventListener('scroll', debounce(handleScroll, 200));
  }, [categories]);

  useEffect(() => {
    if (categoryId >= 1) {
      const nav = document.querySelector(`#nav-${categoryId}`);
      if (nav) {
        nav.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  }, [categoryId]);

  function handleClickCategory(category) {
    setCategoryId(category);
    setTimeout(() => {
      const anchor = document.querySelector(`#category-${category}`);
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500);
  }

  const handleClickItem = product => {
    dispatch(itemSelected(product));
  };

  return (
    <div className={classes.root}>
      <ul className={classes.nav}>
        {categories.map(category => (
          <li id={`nav-${category.category_id}`} key={category.category_id}>
            <button
              type="button"
              className={clsx(classes.item, {
                [classes.selected]: category.category_id === categoryId,
              })}
              onClick={() => {
                handleClickCategory(category.category_id);
              }}
            >
              {`${category.name}`.toLowerCase()}
            </button>
          </li>
        ))}
      </ul>

      {categories.map(category => (
        <Category
          key={category.category_id}
          category={category}
          handleClickItem={handleClickItem}
        />
      ))}
    </div>
  );
}
