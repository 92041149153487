import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    zIndex: 100,
    width: '100%',
    padding: '1rem',
    background: '#fff',
    borderTop: '1px solid rgba(149, 149, 149, 0.4)',
  },
  button: {
    width: '100%',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F97F11',
    '&:hover': {
      backgroundColor: '#F97F11',
    },
    borderRadius: '.5rem',
  },
}));

export default useStyles;
