export const ActionTypes = Object.freeze({
  LOAD: '@addresses/LOAD',
  MODAL: '@addresses/MODAL',
  CREATE_SUCCESS: '@addresses/CREATE_SUCCESS',
});

export function addressesLoad() {
  return {
    type: ActionTypes.LOAD,
  };
}

export function addressesModal() {
  return {
    type: ActionTypes.MODAL,
  };
}

export function addressesCreateSuccess(address) {
  return {
    type: ActionTypes.CREATE_SUCCESS,
    payload: { address },
  };
}
