import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import App from './App';
import GoogleTagManager from './GoogleTagManager';

ReactDOM.render(
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    <GoogleTagManager />
    <App />
  </SnackbarProvider>,
  document.getElementById('root')
);
