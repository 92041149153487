import React from 'react';

import { Container } from './styles';

function Payment({ order }) {
  const { payment } = order;

  return (
    <Container>
      {payment.id === 1 && (
        <>
          <span>Pagamento na entrega</span>
          <span>Dinheiro</span>
        </>
      )}
      {payment.id === 2 && (
        <>
          <span>Pagamento na entrega</span>
          <span>Credito</span>
        </>
      )}
      {payment.id === 3 && (
        <>
          <span>Pagamento na entrega</span>
          <span>Debito</span>
        </>
      )}
      {payment.id === 4 && (
        <>
          <span>Pagamento antecipado</span>
          <span>Pix</span>
        </>
      )}
    </Container>
  );
}

export { Payment };
