/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useSnackbar } from 'notistack';
import MaskService from 'react-masked-text/src/mask-service';
import { MoneyMask } from 'react-masked-text/src/masks/money.mask';
import { useDispatch, useSelector } from 'react-redux';
import { formatPrice } from '../../../utils/format';
import useStyles from './styles';
import { orderPayment } from '../../../store/modules/order/actions';

const paymentValueDefault = { value: '0,00' };

function Confirmation({ modalPayment, setModalPayment, payment }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [paymentValue, setPaymentValue] = useState(paymentValueDefault);
  const [selectedCard, setSelectedCard] = useState('');
  const { company, cards } = useSelector(state => state.data);
  const { formattedTotal, total } = useSelector(state => {
    const { order } = state.order;

    return {
      total: order ? order.total : 0,
      formattedTotal: formatPrice(order ? order.total : 0),
    };
  });

  useEffect(() => {
    if (modalPayment) {
      setPaymentValue(paymentValueDefault);
      setSelectedCard('');
    }
  }, [modalPayment]);

  const handleClose = () => {
    setModalPayment(false);
  };

  const handleConfirm = () => {
    const moneyMaskFormatting = new MoneyMask();
    const value = moneyMaskFormatting.getRawValue(paymentValue.value);

    if (value > 0 && value <= total) {
      enqueueSnackbar(
        `Você deve pedir troco para um valor maior do que ${formattedTotal}`,
        { variant: 'error' }
      );

      setPaymentValue({
        value: paymentValue.value,
        error: true,
      });

      return;
    }

    if (
      cards.length > 0 &&
      (payment === 2 || payment === 3) &&
      selectedCard === ''
    ) {
      enqueueSnackbar('Selecione a bandeira', { variant: 'error' });
      return;
    }

    setModalPayment(false);

    dispatch(
      orderPayment({
        id: payment,
        value,
        formattedValue: formatPrice(value),
        card: selectedCard,
      })
    );
  };

  return company ? (
    <Drawer anchor="bottom" open={modalPayment} onClose={handleClose}>
      {company.recebimentoantecipado === 'N' && (
        <div className={classes.root}>
          <span className={classes.title}>pagamento</span>

          {payment === 1 && (
            <>
              <div className={classes.item}>
                <div>
                  <MonetizationOnIcon fontSize="small" />
                  <div>
                    <span>
                      <small>Pagar na entrega</small>
                    </span>
                    <span>
                      <strong>Dinheiro</strong>
                    </span>
                  </div>
                </div>
                <span onClick={handleClose}>
                  <small>Alterar</small>
                </span>
              </div>

              <span className={classes.troco}>Troco para quanto?</span>

              <TextField
                error={paymentValue.error}
                id="payment-value"
                InputProps={{
                  fullWidth: true,
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                value={paymentValue.value}
                onChange={event => {
                  const money = MaskService.toMask(
                    'money',
                    event.target.value,
                    {
                      unit: '',
                      separator: ',',
                      delimiter: '.',
                    }
                  );
                  setPaymentValue({ value: money });
                }}
              />
            </>
          )}

          {payment === 2 && (
            <>
              <div className={classes.item}>
                <div>
                  <CreditCardIcon fontSize="small" />
                  <div>
                    <span>
                      <small>Pagar na entrega</small>
                    </span>
                    <span>
                      <strong>Crédito</strong>
                    </span>
                  </div>
                </div>
                <span onClick={handleClose}>
                  <small>Alterar</small>
                </span>
              </div>
              <ul className={classes.cards}>
                {cards
                  .filter(
                    card =>
                      card.modality === 'all' || card.modality === 'credito'
                  )
                  .map(card => (
                    <li
                      key={card.name}
                      onClick={() => setSelectedCard(card.name)}
                      className={
                        selectedCard === card.name
                          ? classes.selectedCard
                          : classes.card
                      }
                    >
                      <span>{card.name}</span>
                    </li>
                  ))}
              </ul>
              {cards.length > 0 && selectedCard === '' && (
                <span className={classes.warn}>Selecione a bandeira</span>
              )}
            </>
          )}

          {payment === 3 && (
            <>
              <div className={classes.item}>
                <div>
                  <CreditCardIcon fontSize="small" />
                  <div>
                    <span>
                      <small>Pagar na entrega</small>
                    </span>
                    <span>
                      <strong>Débito</strong>
                    </span>
                  </div>
                </div>
                <span onClick={handleClose}>
                  <small>Alterar</small>
                </span>
              </div>
              <ul className={classes.cards}>
                {cards
                  .filter(
                    card =>
                      card.modality === 'all' || card.modality === 'debito'
                  )
                  .map(card => (
                    <li
                      key={card.name}
                      onClick={() => setSelectedCard(card.name)}
                      className={
                        selectedCard === card.name
                          ? classes.selectedCard
                          : classes.card
                      }
                    >
                      <span>{card.name}</span>
                    </li>
                  ))}
              </ul>
              {cards.length > 0 && selectedCard === '' && (
                <span className={classes.warn}>Selecione a bandeira</span>
              )}
            </>
          )}

          {payment === 4 && (
            <div className={classes.item}>
              <div>
                <AccountBalanceIcon fontSize="small" />
                <div>
                  <span>
                    <small>Pagar agora</small>
                  </span>
                  <span>
                    {company.pixonline === 'S' ? (
                      <strong>Pix</strong>
                    ) : (
                      <strong>{`Pix ${company.pixchave}`}</strong>
                    )}
                  </span>
                </div>
              </div>
              <span onClick={handleClose}>
                <small>Alterar</small>
              </span>
            </div>
          )}

          <button
            type="button"
            className={classes.button}
            onClick={handleConfirm}
          >
            confirmar pagamento
          </button>
        </div>
      )}

      {company.recebimentoantecipado === 'S' && (
        <div className={classes.root}>
          <span className={classes.title}>pagamento</span>

          {payment === 2 && (
            <div className={classes.item}>
              <div>
                <CreditCardIcon fontSize="small" />
                <div>
                  <span>
                    <small>Pagamento antecipado</small>
                  </span>
                  <span>
                    <strong>Crédito</strong>
                  </span>
                </div>
              </div>
              <span onClick={handleClose}>
                <small>Alterar</small>
              </span>
            </div>
          )}

          {payment === 4 && (
            <div className={classes.item}>
              <div>
                <AccountBalanceIcon fontSize="small" />
                <div>
                  <span>
                    <small>Pagamento antecipado</small>
                  </span>
                  <span>
                    <strong>Pix</strong>
                  </span>
                </div>
              </div>
              <span onClick={handleClose}>
                <small>Alterar</small>
              </span>
            </div>
          )}

          <button
            type="button"
            className={classes.button}
            onClick={handleConfirm}
          >
            confirmar pagamento
          </button>
        </div>
      )}
    </Drawer>
  ) : null;
}

export default Confirmation;
