import React from 'react';

import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

import ProductWithoutImage from '../../../../../../assets/ProductWithoutImage.png';

import { formatPrice } from '../../../../../../utils/format';

import useStyles from './styles';

function selecteds(complements) {
  const data = [];
  complements.forEach(complement => {
    complement.items.forEach(complementItem => {
      if (complementItem.amount >= 1) {
        data.push(complementItem);
      }
    });
  });

  return data;
}

function Items({ order }) {
  const classes = useStyles();

  const items = order.items.map(orderItem => {
    return {
      ...orderItem,
      formattedSubtotal: formatPrice(orderItem.subtotal),
      selectedComplements: selecteds(orderItem.complements),
    };
  });

  return (
    items && (
      <div className={classes.root}>
        {items.map(product => (
          <div
            key={JSON.stringify(product.key + product.product_id)}
            className={classes.item}
          >
            <div className={classes.body}>
              <div className={classes.defaults}>
                <div className={classes.media}>
                  <img
                    className={classes.img}
                    src={product.url ? product.url : ProductWithoutImage}
                    alt={product.name}
                  />
                </div>
                <div className={classes.data}>
                  <span className={classes.span}>{`${product.amount}x`}</span>
                  <div className={classes.fields}>
                    <span className={classes.span}>{product.name}</span>
                  </div>
                  <div className={classes.price}>
                    <span>{product.formattedSubtotal}</span>
                  </div>
                </div>
              </div>

              {product.selectedComplements.length >= 1 && (
                <ul className={classes.complements}>
                  {product.selectedComplements &&
                    product.selectedComplements.map(complement => (
                      <li
                        key={JSON.stringify(product.key + complement.item_id)}
                      >
                        {`${complement.amount}x ${complement.name}`}
                      </li>
                    ))}
                </ul>
              )}

              {product.comment !== '' && (
                <div className={classes.comment}>
                  <SpeakerNotesIcon className={classes.icon} />
                  <span>{product.comment}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    )
  );
}

export { Items };
