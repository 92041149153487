import React from 'react';

import { Container } from './styles';

function Identifier({ order }) {
  const { id, date } = order;

  return (
    <Container>
      <span># {id}</span>
      <span>{date}</span>
    </Container>
  );
}

export { Identifier };
