import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    margin: '0',
    padding: '0',
  },
  backButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: '#FF9000',
    cursor: 'pointer',
  },
  dialogContent: {
    marginTop: -20,
  },
  inputGroup: {
    display: 'flex',
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputNumber: {
    width: '60px',
    maxWidth: '80px',
  },
  toggleButtonGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  dialogActions: {
    padding: 20,
    marginTop: 10,
  },
  buttonConfirmAddress: {
    width: '100%',
    height: '50px',
    display: 'flex',
    borderRadius: '.5rem',
    backgroundColor: '#F97F11',
    color: '#fff',

    '&:hover': {
      backgroundColor: '#F97F11',
      opacity: 0.9,
    },
  },
}));

export default useStyles;
