import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import Maps from './Maps';
import useStyles from './styles';
import { addressesCreateSuccess } from '../../../store/modules/addresses/actions';

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    marginTop: 10,
  },
  grouped: {
    border: '1px solid #eee',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(4),
      borderLeftWidth: '1px',
      borderLeftColor: '#eee',
      borderRadius: 5,
    },
    '&:first-child': {
      width: '110px',
      borderRadius: 5,
    },
  },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles(() => ({
  selected: {
    borderColor: '#999',
    '&:not(:first-child)': {
      borderLeftWidth: '1px',
      borderLeftColor: '#999',
      borderRadius: 5,
    },
    '&:first-child': {
      width: '120px',
      borderRadius: 5,
    },
  },
}))(ToggleButton);

function ConfirmAddress({ address, setAddress }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();

  const [number, setNumber] = useState(address.number);
  const [complement, setComplement] = useState(address.complement);
  const [comments, setComments] = useState(address.comments);
  const [name, setName] = useState('');

  const handleClose = () => {
    setAddress(null);
  };

  const renderMap = useMemo(() => {
    return (
      <Maps coordinates={{ lat: address.latitude, lng: address.longitude }} />
    );
  }, [address]);

  const handleFavoritePlace = (event, newName) => {
    setName(newName);
  };

  const handleConfirm = useCallback(async () => {
    if (number.trim().length === 0) {
      enqueueSnackbar('Informe o número do seu endereço.', {
        variant: 'error',
      });
      return;
    }

    const data = {
      ...address,
      name: name.length === 0 ? address.public_place : name,
      number,
      complement: complement.trim().length === 0 ? null : complement.trim(),
      comments: comments.trim().length === 0 ? null : comments.trim(),
    };

    dispatch(addressesCreateSuccess(data));

    setAddress(null);
  }, [
    address,
    name,
    number,
    complement,
    comments,
    dispatch,
    enqueueSnackbar,
    setAddress,
  ]);

  return (
    <Dialog fullScreen={fullScreen} open={!!address} onClose={handleClose}>
      {renderMap}

      <IconButton className={classes.backButton} onClick={handleClose}>
        <ArrowBackIosIcon />
      </IconButton>

      <DialogTitle>
        {address.public_place}, {number}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          {address.neighborhood}, {address.city}, {address.state}
        </DialogContentText>

        <Box className={classes.inputGroup}>
          <TextField
            defaultValue={number}
            label="Número"
            className={classes.inputNumber}
            onChange={event => {
              setNumber(event.target.value.substr(0, 100));
            }}
          />

          <TextField
            label="Complemento"
            helperText="Apto/Bloco/Casa"
            value={complement}
            onChange={event => {
              setComplement(event.target.value.substr(0, 100));
            }}
          />
        </Box>

        <TextField
          label="Ponto de referência"
          helperText="Ex: Em frente á praça"
          fullWidth
          value={comments}
          onChange={event => {
            setComments(event.target.value.substr(0, 100));
          }}
        />

        <Typography style={{ marginTop: 20 }}>Favoritar como:</Typography>

        <Box className={classes.toggleButtonGroup}>
          <StyledToggleButtonGroup
            value={name}
            exclusive
            onChange={handleFavoritePlace}
          >
            <StyledToggleButton value="Casa" aria-label="home">
              <HomeOutlinedIcon />
              <Typography style={{ fontSize: 12, marginLeft: 5 }}>
                Casa
              </Typography>
            </StyledToggleButton>

            <StyledToggleButton value="Trabalho" aria-label="work">
              <WorkOutlineOutlinedIcon />
              <Typography style={{ fontSize: 12, marginLeft: 5 }}>
                Trabalho
              </Typography>
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.buttonConfirmAddress}
          variant="contained"
          color="primary"
          onClick={handleConfirm}
        >
          salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmAddress;
