import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '15px 0',
    borderBottom: '1px solid #eee',
  },
  fields: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  fieldsName: {
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  fieldsPrice: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginTop: '5px',
  },
  actions: {
    width: '110px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: '5px',
  },
  actionsAmount: {
    width: '30px',
    textAlign: 'center',
  },
});

export default useStyles;
