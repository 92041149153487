import { format } from 'date-fns';
import {
  getOrder,
  getOrders,
  setOrder,
  setOrders,
} from '../../../infra/config/storage';
import { ActionTypes } from './actions';

export default function order(
  state = {
    order: null,
    modalOrder: false,
    modalOrderSuccess: false,
    lastScheduledOrder: false,
    modalCheckout: false,
  },
  action
) {
  switch (action.type) {
    case ActionTypes.CREATE: {
      if (state.order) return state;

      const data = getOrder();

      return {
        order: data,
        modalOrder: false,
        modalOrderSuccess: false,
        modalCheckout: false,
      };
    }

    case ActionTypes.CONFIRMED: {
      const {
        id,
        moment,
        pix,
        pixtxid,
        pixqrcode,
        pixexpiration,
        pixsituacao,
      } = action.payload;
      const currentDate = new Date(moment.split(' ').join('T'));
      const currentOrder = {
        ...state.order,
        id,
        date: format(currentDate, 'dd/MM/yyyy HH:mm'),
        status: 'created',
        moment,
        pix,
        pixtxid,
        pixqrcode,
        pixexpiration,
        pixsituacao,
      };
      const orders = getOrders();
      orders.unshift(currentOrder);
      setOrders(orders);

      setOrder(null);
      const data = getOrder();
      return {
        order: data,
        modalOrder: false,
        modalOrderSuccess: pix !== 'S',
        lastScheduledOrder: state.order.scheduling,
      };
    }

    case ActionTypes.ADD_ITEM: {
      const { item: data } = action.payload;
      if (data) {
        let items = [];
        const itemOld = state.order.items.find(item => item.key === data.key);
        if (itemOld) {
          items = state.order.items.map(item => {
            if (item.key === data.key) return data;
            return item;
          });
        } else {
          items = [...state.order.items, data];
        }

        const subtotal = items.reduce((sum, item) => {
          return sum + item.subtotal;
        }, 0);

        const currentOrder = {
          ...state.order,
          items,
          subtotal,
          total: subtotal + state.order.delivery,
          payment: { id: 0, value: 0 },
        };

        setOrder(currentOrder);

        return { ...state, order: currentOrder };
      }

      return state;
    }

    case ActionTypes.REMOVE_ITEM: {
      const { key } = action.payload;
      const items = state.order.items.filter(item => item.key !== key);

      const subtotal = items.reduce((sum, item) => {
        return sum + item.subtotal;
      }, 0);

      const currentOrder = {
        ...state.order,
        items,
        subtotal,
        total: subtotal + state.order.delivery,
        payment: { id: 0, value: 0 },
      };

      setOrder(currentOrder);

      return {
        ...state,
        order: currentOrder,
        modalOrder: items.length >= 1,
      };
    }

    case ActionTypes.SET_ADDRESS: {
      const { address } = action.payload;

      if (
        state.order &&
        state.order.address &&
        state.order.address.key === address.key
      )
        return state;

      return {
        ...state,
        order: {
          ...state.order,
          address,
          delivery: address.delivery || 0,
          total: state.order.subtotal + (address.delivery || 0),
          payment: { id: 0, value: 0 },
        },
      };
    }

    case ActionTypes.SHOW: {
      return {
        ...state,
        order: {
          ...state.order,
          scheduling: false,
          day: '',
          hour: '',
          minutes: '',
        },
        modalOrder: true,
      };
    }

    case ActionTypes.CLOSE: {
      return {
        ...state,
        modalOrder: false,
      };
    }

    case ActionTypes.PAYMENT: {
      const { payment } = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          payment,
        },
      };
    }

    case ActionTypes.CHANGE_MODALITY: {
      const { modality } = action.payload;
      if (modality === state.order.modality) {
        return state;
      }

      return {
        ...state,
        order: {
          ...state.order,
          address: null,
          delivery: 0,
          total: state.order.subtotal,
          modality,
          payment: { id: 0, value: 0 },
        },
      };
    }

    case ActionTypes.SCHEDULING: {
      const { scheduling, day, hour, minutes } = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          scheduling,
          day,
          hour,
          minutes,
        },
      };
    }

    case ActionTypes.SUCCESS: {
      return {
        ...state,
        modalOrderSuccess: false,
      };
    }

    case ActionTypes.CHECKOUT: {
      return {
        ...state,
        modalCheckout: !state.modalCheckout,
      };
    }

    case ActionTypes.CONFIRMED_PIX: {
      return {
        ...state,
        modalOrderSuccess: true,
      };
    }

    default:
      return state;
  }
}
