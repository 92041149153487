import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import api from 'axios';
import { OnlyNumbersMask } from 'react-masked-text/src/masks/only-numbers.mask';
import ConfirmAddress from '../ConfirmAddress';
import { findCEP } from '../../../utils/findCEP';
import { PageHeader } from '../../../components/PageHeader';

import useStyles from './styles';

function FindAddress({ findAddressModal, setFindAddressModal }) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  const [consulted, setConsulted] = useState(false);

  const [cep, setCep] = useState('');
  const [numberCep, setNumberCep] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [codState, setCodState] = useState('');
  const [codCity, setCodCity] = useState('');

  const [address, setAddress] = useState(null);

  const { addresses } = useSelector(st => st.addresses);

  const cepOnChange = event => {
    const value = event.target.value.substr(0, 9);
    setCep(value);
    const onlyNumbers = new OnlyNumbersMask();
    setNumberCep(onlyNumbers.getRawValue(value));
  };

  const handleClose = useCallback(() => {
    setFindAddressModal(false);
    setConsulted(false);
    setCep('');
    setState('');
    setCity('');
    setNeighborhood('');
    setStreet('');
    setNumber('');
    setCodState('');
    setCodCity('');
  }, [
    setFindAddressModal,
    setConsulted,
    setCep,
    setState,
    setCity,
    setNeighborhood,
    setStreet,
    setNumber,
    setCodState,
    setCodCity,
  ]);

  const createAddress = selectedAddress => {
    const builtAddress = {
      name: '',
      zip_code: numberCep,
      state,
      city,
      neighborhood: neighborhood.trim(),
      public_place: street.trim(),
      number: number.trim(),
      complement: '',
      code_state: codState,
      code_city: codCity,
      comments: '',
      latitude: selectedAddress.geometry.location.lat,
      longitude: selectedAddress.geometry.location.lng,
    };

    setAddress(builtAddress);
  };

  const handleFindOnGoogleMaps = async () => {
    setConsulted(true);

    if (
      numberCep.length !== 8 ||
      (numberCep.length === 8 && state.length === 0)
    ) {
      enqueueSnackbar('Digite um CEP válido', {
        variant: 'error',
      });
      return;
    }

    if (
      neighborhood.length === 0 ||
      street.length === 0 ||
      number.length === 0
    ) {
      enqueueSnackbar('Preencha todos os campos para continuar.', {
        variant: 'error',
      });
      return;
    }

    try {
      const fields = [];

      fields.push(state);
      fields.push(
        city
          .trim()
          .split(' ')
          .join('+')
      );
      fields.push(
        neighborhood
          .trim()
          .split(' ')
          .join('+')
      );
      fields.push(
        street
          .trim()
          .split(' ')
          .join('+')
      );
      fields.push(number.trim());

      const formatedAddress = fields.join('+');

      const response = await api.get(
        'https://maps.googleapis.com/maps/api/geocode/json',
        {
          params: {
            address: formatedAddress,
            region: 'br',
            key: process.env.REACT_APP_GOOLE_MAPS_KEY,
          },
        }
      );

      const { results } = response.data;

      if (results.length) {
        createAddress(results[0]);
      } else {
        throw new Error('Nenhum endereço encontrado.');
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    async function findAddress(value) {
      const data = await findCEP(value);

      if (data.cep.length === 0) {
        setState('');
        setCodState('');
        setCity('');
        setCodCity('');
        return;
      }

      const { uf, coduf, cidade, codcidade, bairro, rua } = data;

      setState(uf);
      setCity(cidade);
      setNeighborhood(bairro);
      setStreet(rua);
      setCodCity(codcidade);
      setCodState(coduf);
    }

    if (numberCep.length === 8) {
      findAddress(numberCep);
    }
  }, [numberCep]);

  useEffect(() => {
    handleClose();
  }, [addresses, handleClose]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={findAddressModal}
        className={classes.root}
      >
        <PageHeader handleClose={handleClose} title="adicionando" />
        <div className={classes.content}>
          <div className={classes.fields}>
            <TextField
              error={
                consulted && (numberCep.length !== 8 || state.length === 0)
              }
              label="CEP"
              placeholder="Digite seu CEP"
              value={cep}
              onChange={cepOnChange}
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              error={consulted && neighborhood.length === 0}
              label="Bairro"
              placeholder="Nome do bairro"
              value={neighborhood}
              onChange={event => {
                setNeighborhood(event.target.value.substr(0, 100));
              }}
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              error={consulted && street.length === 0}
              label="Rua"
              placeholder="Nome da rua"
              value={street}
              onChange={event => {
                setStreet(event.target.value.substr(0, 100));
              }}
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              error={consulted && number.length === 0}
              label="Número"
              placeholder="Número da casa ou condomínio"
              value={number}
              onChange={event => {
                setNumber(event.target.value.substr(0, 10));
              }}
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Button
              className={classes.button}
              variant="contained"
              onClick={handleFindOnGoogleMaps}
            >
              Continuar
            </Button>
          </div>
        </div>
      </Dialog>

      {!!address && (
        <ConfirmAddress address={address} setAddress={setAddress} />
      )}
    </>
  );
}

export default FindAddress;
