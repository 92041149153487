import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',

    padding: '20px 15px 0 15px',
    background: 'rgb(243, 245, 247) none repeat scroll 0% 0%',
  },
  payments: {
    marginBottom: '20px',
  },
  title: {
    display: 'block',
    fontSize: '14px',
    lineHeight: '150%',
    fontWeight: '600',
    color: 'rgb(72,84,96)',
    marginBottom: '10px',
  },
  list: {
    listStyle: 'none',
    margin: '0px',
    padding: '0px',
  },
  item: {
    width: '100%',
    height: '60px',
    padding: '0 15px',
    borderRadius: '5px',
    border: '1px solid rgb(232,234,237)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'rgb(255, 255, 255) none repeat scroll 0% 0%',
    color: '#000',
    marginBottom: '7.5px',

    '& div': {
      display: 'flex',
      alignItems: 'center',

      '& span': {
        marginTop: '4px',
        marginLeft: '7.5px',
        textTransform: 'capitalize',
        color: 'rgb(72, 84, 96)',
      },
    },
    cursor: 'pointer',
  },
});

export default useStyles;
