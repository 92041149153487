import { getClient, setClient } from '../../../infra/config/storage';
import { ActionTypes } from './actions';

export default function data(
  state = {
    loading: true,
    modalMenu: false,
    token: '',
    company: {},
    categories: [],
    kms: [],
    client: { name: '', phone: '' },
    districts: [],
    cards: [],
  },
  action
) {
  switch (action.type) {
    case ActionTypes.LOAD: {
      const {
        token,
        company,
        categories,
        kms,
        districts,
        cards,
      } = action.payload;
      const client = getClient();

      return {
        ...state,
        token,
        company,
        categories,
        kms,
        districts,
        client,
        loading: false,
        cards,
      };
    }

    case ActionTypes.SET_CLIENT: {
      const { client } = action.payload;
      setClient(client);
      return { ...state, client };
    }

    case ActionTypes.LOADING: {
      return {
        ...state,
        loading: !state.loading,
      };
    }

    case ActionTypes.MENU: {
      return {
        ...state,
        modalMenu: !state.modalMenu,
      };
    }

    default:
      return state;
  }
}
