import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '15px',
    background: 'rgb(243, 245, 247) none repeat scroll 0% 0%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '16px',
    lineHeight: '120%',
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'rgb(48,51,60)',
    textTransform: 'capitalize',
    marginBottom: '15px',
  },
  item: {
    padding: '10px 15px',
    borderRadius: '5px',
    border: '1px solid rgb(232,234,237)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'rgb(255, 255, 255) none repeat scroll 0% 0%',

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',

      '& > div': {
        height: '48px',
        marginTop: '2px',
        marginLeft: '7.5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '& > span': {
          color: 'rgb(72, 84, 96)',
        },
      },
    },
  },
  troco: {
    fontSize: '14px',
    lineHeight: '150%',
    fontWeight: 'bold',
    color: 'rgb(72,84,96)',
    margin: '30px 0 10px',
  },
  button: {
    height: '48px',
    borderRadius: '5px',
    border: 'medium none',
    margin: '48px 0 0 0',
    background: 'rgb(48, 51, 60) none repeat scroll 0% 0%',
    color: '#fff',
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '150%',
  },
  cards: {
    maxHeight: 'calc(100vh / 2 - 4rem)',
    overflow: 'auto',
    margin: '0 0 0 0',
    padding: '1rem 1rem 0 2.5rem',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.3rem',
  },
  card: {
    textTransform: 'capitalize',
    padding: '0.3rem 0',
    cursor: 'pointer',
  },
  selectedCard: {
    textTransform: 'capitalize',
    padding: '0.3rem 0',
    textAlign: 'center',
    cursor: 'pointer',
    background: 'rgb(48, 51, 60) none repeat scroll 0% 0%',
    color: '#fff',
    borderRadius: '0.25rem',
  },
  warn: {
    padding: '1rem 0 0 0',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'red',
    fontWeight: '500',
    fontSize: '0.85rem',
  },
});

export default useStyles;
