import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    padding: '16px',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 175,
    '& img': {
      width: 175,
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
    fontSize: '16px',
    color: '#333',
    '& span:first-child': {
      fontSize: '20px',
      fontWeight: '500',
    },
    '& span': {
      textAlign: 'center',
    },
  },
  input: {
    width: '100%',
    height: '48px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '0 0.5rem',
    color: '#333',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.5rem',
    '& input': {
      width: '100%',
      flex: '1',
      border: 'none',
      background: 'transparent',
      outline: 'none',
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    '& svg': {
      cursor: 'pointer',
    },
  },
  progress: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& span': {
      fontSize: '16px',
      color: '#333',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& button': {
      padding: '0.75rem',
    },
  },
}));

export default useStyles;
