import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  screen: {
    width: 296,
  },
  Avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  List: {
    paddingLeft: '15px',
    backgroundColor: '#F97F11',
    color: '#fff',
  },
  ListItem: {
    padding: 0,
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifycontent: 'center',
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
  },
  userName: {
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  userEmail: {
    fontSize: '12px',
  },
}));

export default useStyles;
