import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    width: '100%',
    minHeight: '64px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '7.5px 15px',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
  },
  body: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  defaults: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  media: {
    width: '48px',
    height: '48px',
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: '6px',
    border: '3px solid rgba(149, 149, 149, 0.4)',
    backgroundSize: 'cover',
  },
  data: {
    flex: '1',
    marginLeft: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  span: {
    marginRight: '15px',
    fontSize: '0.875rem',
    fontWeight: '500',
  },
  fields: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  price: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    fontSize: '0.875rem',
    fontWeight: '300',
  },
  complements: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.675rem',
    fontWeight: '400',
    margin: '0px 0px 0px 63px',
    padding: '0px 0px 0px 3px',
    listStyle: 'none',
  },
  comment: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '0.675rem',
    fontWeight: '400',
    margin: '10px 0px 0px 0px',
  },
  icon: {
    fontSize: '0.875rem',
    fontWeight: '400',
    marginRight: '5px',
  },
}));

export default useStyles;
