import React from 'react';

import { Container } from './styles';

function Modality({ order }) {
  const { modality } = order;

  return (
    <Container>
      <span>Modalidade</span>
      {modality === 0 && <span>Entrega</span>}
      {modality === 1 && <span>Retirada</span>}
    </Container>
  );
}

export { Modality };
