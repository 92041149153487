import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { formatPrice } from '../../utils/format';
import useStyles from './styles';
import { orderShow } from '../../store/modules/order/actions';

export default function OrderButton() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const count = useSelector(state => state.order.order.items.length);
  const total = formatPrice(
    useSelector(state =>
      state.order.order.items.reduce((sumtotal, item) => {
        return sumtotal + item.subtotal;
      }, 0)
    )
  );

  const handleOrder = () => {
    dispatch(orderShow());
  };

  return (
    <Button color="primary" className={classes.button} onClick={handleOrder}>
      <Typography className={classes.title}>{count}</Typography>
      <Typography className={classes.title}>Ver pedido</Typography>
      <Typography className={classes.title}>{total}</Typography>
    </Button>
  );
}
