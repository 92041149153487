import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaskService from 'react-masked-text/src/mask-service';
import { OnlyNumbersMask } from 'react-masked-text/src/masks/only-numbers.mask';
import useStyles from './styles';
import { dataSetClient } from '../../../store/modules/data/actions';

function Client() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [inputName, setInputName] = useState(undefined);
  const [inputPhone, setInputPhone] = useState(undefined);

  const { client } = useSelector(state => state.data);

  useEffect(() => {
    if (inputName === undefined) {
      setInputName(client.name);
      const phone = MaskService.toMask('cel-phone', client.phone);
      setInputPhone(phone);
    }
  }, [client, inputName]);

  useEffect(() => {
    const delayDebounce = setTimeout(async () => {
      if (inputName !== undefined && inputName !== client.name) {
        dispatch(dataSetClient({ ...client, name: inputName }));
      }
    }, 2000);

    return () => clearTimeout(delayDebounce);
  }, [inputName, client, dispatch]);

  useEffect(() => {
    const delayDebounce = setTimeout(async () => {
      const onlyNumbers = new OnlyNumbersMask();
      const phone = onlyNumbers.getRawValue(inputPhone.trim());
      if (inputPhone !== undefined && phone !== client.phone) {
        dispatch(dataSetClient({ ...client, phone }));
      }
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [inputPhone, client, dispatch]);

  return (
    <div className={classes.root}>
      <div className={classes.name}>
        <strong>Qual o seu nome?</strong>
        <input
          type="text"
          value={inputName || ''}
          onChange={event => setInputName(event.target.value)}
        />
      </div>

      <div className={classes.fone}>
        <strong>Seu telefone</strong>
        <small>(caso a gente precise falar com voce)</small>
        <input
          type="text"
          value={inputPhone || ''}
          onChange={event => {
            const phone = MaskService.toMask('cel-phone', event.target.value);
            setInputPhone(phone);
          }}
        />
      </div>
    </div>
  );
}

export default Client;
