export const ActionTypes = Object.freeze({
  MODAL: '@payments/MODAL',
  PAYMENT: '@order/PAYMENT',
});

export function paymentsModal() {
  return {
    type: ActionTypes.MODAL,
  };
}
