import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  > span {
    font-weight: 500;
    color: #666;
    margin-bottom: 0.5rem;
  }
`;

export const Totals = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > span {
    font-weight: 300;
    color: #666;
  }

  :last-child {
    span {
      font-weight: 500;
    }
  }
`;
