import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { useMediaQuery, useTheme, Button, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { addHours } from 'date-fns';
import { useSnackbar } from 'notistack';
import PixImage from '../../assets/pix-logo.png';

import useStyles from './styles';
import { Steps } from './Steps';
import {
  getOrders,
  getOrders as ordersget,
  setOrders as ordersset,
} from '../../infra/config/storage';
import { pix } from '../../services/application';
import { orderConfirmedPix } from '../../store/modules/order/actions';

function Pix() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [showSteps, setShowSteps] = useState(false);
  const [order, setOrder] = useState(null);
  const [firstProgress, setFirstProgress] = useState(100);
  const [secondProgress, setSecondProgress] = useState(100);
  const [pixexpiration, setPixExpiration] = useState('00:00');

  const { company } = useSelector(state => state.data);
  const { order: current } = useSelector(state => state.order);

  const handleShowSteps = () => {
    setShowSteps(true);
  };

  const handleCloseSteps = () => {
    setShowSteps(false);
  };

  const handleCopy = ({ pixqrcode }) => {
    copy(pixqrcode);
    enqueueSnackbar('Copiado com sucesso!', { variant: 'success' });
  };

  useEffect(() => {
    if (current && current.total === 0) {
      const orders = getOrders();
      const data = orders.find(
        item =>
          item.pix === 'S' &&
          (item.pixsituacao === 'PENDENTE' || item.pixsituacao === 'ATIVA')
      );
      setOrder(data);
      setFirstProgress(100);
    }
  }, [current]);

  useEffect(() => {
    if (
      order &&
      (order.pixsituacao === 'PENDENTE' || order.pixsituacao === 'ATIVA')
    ) {
      const interval = setInterval(async () => {
        const { id } = order;
        const data = await pix(id);
        if (data && data.id) {
          const orders = ordersget();
          const updatedOrders = orders.map(item => {
            if (item.id === data.id) {
              return {
                ...item,
                ...data,
              };
            }
            return item;
          });
          ordersset(updatedOrders);
          if (data.pixsituacao !== order.pixsituacao) {
            setOrder({ ...order, ...data });
            const { pixsituacao } = data;
            if (pixsituacao === 'CONCLUIDA') {
              dispatch(orderConfirmedPix());
              setOrder(null);
              clearInterval(interval);
            } else if (pixsituacao === 'REMOVIDA') {
              setOrder(null);
              clearInterval(interval);
              enqueueSnackbar('Seu pedido foi cancelado', {
                variant: 'error',
              });
            }
          }
        }
      }, 5000);

      return () => clearInterval(interval);
    }
    return () => {};
  }, [order, dispatch, enqueueSnackbar]);

  useEffect(() => {
    if (order && order.pixsituacao === 'PENDENTE') {
      const interval = setInterval(() => {
        setFirstProgress(prev => {
          if (prev <= 0) {
            clearInterval(interval);
            return 0;
          }
          return prev - 20;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
    return () => {};
  }, [order]);

  useEffect(() => {
    if (order && order.pixexpiration !== '') {
      const interval = setInterval(() => {
        const { pixhoras } = company;
        const moment = addHours(new Date(), pixhoras);
        const expiration = new Date(order.pixexpiration.split(' ').join('T'));
        const diff = expiration.getTime() - moment.getTime();
        const ex = diff / 1000 >= 300 ? 300 : diff / 1000;
        const progress = Math.floor(ex / 3);
        const minutesDiff = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const secondsDiff = Math.floor((diff % (1000 * 60)) / 1000);
        const minutes = minutesDiff < 10 ? `0${minutesDiff}` : minutesDiff;
        const seconds = secondsDiff < 10 ? `0${secondsDiff}` : secondsDiff;
        const time = ex > 0 ? `${minutes}:${seconds}` : '00:00';
        setPixExpiration(time);
        setSecondProgress(progress);
      }, 1000);

      return () => clearInterval(interval);
    }

    return () => {};
  }, [order, company]);

  return (
    <Dialog fullScreen={fullScreen} open={!!order} className={classes.root}>
      <div className={classes.content}>
        <div className={classes.logo}>
          <img src={PixImage} alt="Pix" />
        </div>

        {order && order.pixsituacao === 'ATIVA' && (
          <>
            <div className={classes.description}>
              <span>Pedido aguardando pagamento</span>
              <span>
                Copie o código abaixo e utilize o Pix Copia e Cola no aplicativo
                que você vai fazer o pagamento
              </span>
              <div className={classes.input}>
                <input type="text" value={order.pixqrcode} readOnly />

                <IconButton onClick={() => handleCopy(order)}>
                  <FileCopyOutlinedIcon />
                </IconButton>
              </div>
            </div>

            <div className={classes.progress}>
              <span>O tempo para você pagar termina em:</span>
              <span>{pixexpiration}</span>
              <LinearProgress
                color="secondary"
                variant="determinate"
                value={secondProgress}
              />
            </div>

            <div className={classes.actions}>
              <Button variant="outlined" onClick={handleShowSteps}>
                Veja como funciona
              </Button>

              <Button
                color="secondary"
                variant="contained"
                onClick={() => handleCopy(order)}
              >
                Copiar
              </Button>
            </div>
          </>
        )}

        {order && order.pixsituacao === 'PENDENTE' && (
          <div className={classes.progress}>
            <span>Gerando uma chave pix</span>
            <LinearProgress
              color="secondary"
              variant="determinate"
              value={firstProgress}
            />
          </div>
        )}
      </div>

      <Steps open={showSteps} onClose={handleCloseSteps} />
    </Dialog>
  );
}

export { Pix };
