import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  nav: {
    top: 0,
    backgroundColor: '#fafafa',
    position: 'sticky',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    whiteSpace: 'nowrap',
    listStyle: 'none',
    margin: '0px',
    padding: '0px',

    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  item: {
    padding: '20px 7.5px',
    textTransform: 'capitalize',
    backgroundColor: '#fafafa',
    color: '#000',
    border: 'none',
    fontSize: '0.975rem',
    fontWeight: '500',
  },
  selected: {
    color: '#F97F11',
    borderBottom: '2px solid #F97F11',
  },
});

export default useStyles;
