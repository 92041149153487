import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';

import { useSnackbar } from 'notistack';
import { formatPrice } from '../../../utils/format';

import {
  orderCheckout,
  orderConfirmed,
} from '../../../store/modules/order/actions';
import { dataLoading } from '../../../store/modules/data/actions';
import { orderset } from '../../../services/application';

import { Container, Button } from './styles';
import Totals from '../Totals';

const removerCaracteresEspeciais = string => {
  if (!string || string.length === 0) {
    return '';
  }

  const string_sem_especiais = string.replace(/[^\p{L}\p{N}\s]/gu, '');

  const string_sem_acentos = string_sem_especiais
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return string_sem_acentos.trim();
};

function Checkout() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { company, loading, client } = useSelector(state => state.data);
  const { modalCheckout, order: current } = useSelector(state => state.order);

  const handleConfirm = useCallback(
    async (com, cli, cur, load) => {
      if (load) return;

      if (com.somenteagendamento === 'S' && !cur.scheduling) {
        enqueueSnackbar(
          'Por favor, agende uma data e hora de entrega/retirada',
          {
            variant: 'error',
          }
        );

        return;
      }

      if (com.enabled !== 1 && !cur.scheduling) {
        enqueueSnackbar('Indisponível no momento', { variant: 'error' });

        return;
      }

      if (cur.modality !== 0 && cur.modality !== 1) {
        enqueueSnackbar('Entrega ou Retirada no local?', {
          variant: 'error',
        });

        return;
      }

      if (cur.modality === 0 && cur.subtotal < com.minimum_value) {
        const formattedMinimumValue = formatPrice(com.minimum_value);
        enqueueSnackbar(
          `O valor minimo para entrega é de ${formattedMinimumValue}`,
          { variant: 'error' }
        );

        return;
      }

      if (cur.scheduling && cur.day.length === 0) {
        enqueueSnackbar('Selecione o dia para agendar o pedido', {
          variant: 'error',
        });

        return;
      }

      if (cur.scheduling && cur.hour.length === 0) {
        enqueueSnackbar('Selecione a hora para agendar o pedido', {
          variant: 'error',
        });

        return;
      }

      if (cur.modality === 0 && !cur.address) {
        enqueueSnackbar('Selecione um endereço para entrega', {
          variant: 'error',
        });

        return;
      }

      if (cur.payment.id === 0) {
        enqueueSnackbar('Selecione a forma de pagamento', { variant: 'error' });

        return;
      }

      if (cli.name.length === 0) {
        enqueueSnackbar('Qual o seu nome?', { variant: 'error' });

        return;
      }

      if (cli.phone.length <= 9) {
        enqueueSnackbar('Seu telefone deve ser preenchido corretamente', {
          variant: 'error',
        });

        return;
      }

      dispatch(dataLoading());

      const order = {
        version: '1.0.2',
        modality: cur.modality,
        deliveryfee: cur.modality === 0 ? cur.delivery : 0,
        client: cur.modality === 0 ? { ...cur.address, ...cli } : { ...cli },
        payment: (() => {
          const { id, value, card = '' } = cur.payment;
          return {
            id,
            value,
            card,
          };
        })(),
        items: cur.items.map(item => {
          const subitems = [];

          item.complements.forEach(complement => {
            complement.items
              .filter(subitem => subitem.amount >= 1)
              .forEach(subitem => {
                const { product_id, name, amount, price } = subitem;
                subitems.push({
                  product_id,
                  name,
                  amount,
                  price: complement.required === 'N' ? price : 0,
                  subtotal: complement.required === 'N' ? price * amount : 0,
                });
              });
          });

          const { product_id, name, amount, price, comment } = item;
          const subtotal = amount * price;
          const totalsubitems = subitems.reduce((a, b) => {
            return a + b.subtotal;
          }, 0);

          return {
            product_id,
            name,
            amount,
            price,
            subtotal,
            totalsubitems: amount * totalsubitems,
            total: subtotal + amount * totalsubitems,
            comment: removerCaracteresEspeciais(comment),
            subitems,
          };
        }),
        subtotal: cur.subtotal,
        total: cur.total,
        scheduling: cur.scheduling
          ? `${cur.day} ${cur.hour}:${cur.minutes}:00`
          : null,
      };

      try {
        const response = await orderset(order);

        if (response && response.success) {
          dispatch(orderConfirmed(response.data));
          dispatch(dataLoading());
          return;
        }

        if (response && response.error) {
          dispatch(dataLoading());
          enqueueSnackbar(response.error_message, { variant: 'error' });
          return;
        }

        dispatch(dataLoading());
        enqueueSnackbar(
          'Houve uma falha interna e não foi possível salvar os dados do seu pedido. Tente mais tarde',
          { variant: 'error' }
        );
      } catch (error) {
        dispatch(dataLoading());

        enqueueSnackbar(
          'Falha ao tentar enviar seu pedido. Verifique sua conexão com a internet',
          { variant: 'error' }
        );
      }
    },
    [dispatch, enqueueSnackbar]
  );

  return (
    <Drawer
      anchor="bottom"
      open={modalCheckout}
      onClose={() => dispatch(orderCheckout())}
    >
      <Container>
        <Totals />
        <Button
          onClick={() => handleConfirm(company, client, current, loading)}
        >
          Confirmar Pedido
        </Button>
      </Container>
    </Drawer>
  );
}

export { Checkout };
