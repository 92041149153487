import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
  },
  scheduling: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& span': {
      minWidth: '3rem',
    },
  },
  list: {
    padding: '.25rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '10rem',
    gap: '.5rem',

    overflow: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },

    borderLeft: '1px solid rgba(149, 149, 149, 0.4)',
  },
  button: {
    minWidth: '4rem',
    padding: '.8rem 1rem',
    textTransform: 'capitalize',
    backgroundColor: '#011133',
    color: '#fff',
    border: 'none',
    borderRadius: '.5rem',
    cursor: 'pointer',
  },
  buttonSelected: {
    backgroundColor: '#F97F11',
    fontWeight: '700',
  },
}));

export default useStyles;
