export const ActionTypes = Object.freeze({
  CREATE: '@order/CREATE',
  ADD_ITEM: '@order/ADD_ITEM',
  REMOVE_ITEM: '@order/REMOVE_ITEM',
  SET_ADDRESS: '@order/SET_ADDRESS',
  SHOW: '@order/SHOW',
  CLOSE: '@order/CLOSE',
  PAYMENT: '@order/PAYMENT',
  CHANGE_MODALITY: '@order/CHANGE_MODALITY',
  SCHEDULING: '@order/SCHEDULING',
  CONFIRMED: '@order/CONFIRMED',
  SUCCESS: '@order/SUCCESS',
  CHECKOUT: '@order/CHECKOUT',
  CONFIRMED_PIX: '@order/CONFIRMED_PIX',
});

export function orderCreate() {
  return {
    type: ActionTypes.CREATE,
  };
}

export function orderAddItem(item) {
  return {
    type: ActionTypes.ADD_ITEM,
    payload: { item },
  };
}

export function orderRemoveItem(key) {
  return {
    type: ActionTypes.REMOVE_ITEM,
    payload: { key },
  };
}

export function orderSetAddress(address) {
  return {
    type: ActionTypes.SET_ADDRESS,
    payload: { address },
  };
}

export function orderShow() {
  return {
    type: ActionTypes.SHOW,
  };
}

export function orderClose() {
  return {
    type: ActionTypes.CLOSE,
  };
}

export function orderPayment(payment) {
  return {
    type: ActionTypes.PAYMENT,
    payload: { payment },
  };
}

export function orderChangeModality(modality) {
  return {
    type: ActionTypes.CHANGE_MODALITY,
    payload: { modality },
  };
}

export function orderScheduling({ scheduling, day, hour, minutes }) {
  return {
    type: ActionTypes.SCHEDULING,
    payload: { scheduling, day, hour, minutes },
  };
}

export function orderConfirmed({
  id,
  moment,
  pix,
  pixtxid,
  pixqrcode,
  pixexpiration,
  pixsituacao,
}) {
  return {
    type: ActionTypes.CONFIRMED,
    payload: {
      id,
      moment,
      pix,
      pixtxid,
      pixqrcode,
      pixexpiration,
      pixsituacao,
    },
  };
}

export function orderSuccess() {
  return {
    type: ActionTypes.SUCCESS,
  };
}

export function orderCheckout() {
  return {
    type: ActionTypes.CHECKOUT,
  };
}

export function orderConfirmedPix() {
  return {
    type: ActionTypes.CONFIRMED_PIX,
  };
}
