import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '15px 0px',
    backgroundColor: '#fafafa',
    color: '#000',
    textAlign: 'start',
    cursor: 'pointer',
    border: 'none',
    borderTop: '1px solid rgba(149, 149, 149, 0.1)',
  },
  fields: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '5px',
  },
  name: {
    textTransform: 'capitalize',
    fontWeight: '500',
    fontSize: '0.775rem',
    paddingBottom: '7.5px',
  },
  description: {
    fontWeight: '400',
    fontSize: '0.725rem',
    paddingBottom: '7.5px',
  },
  price: {
    fontWeight: '500',
    fontSize: '0.775rem',
  },
  media: {
    width: '96px',
    height: '96px',
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: '6px',
    border: '1px solid rgba(149, 149, 149, 0.4)',
    backgroundSize: 'cover',
  },
});

export default useStyles;
