import { ActionTypes } from './actions';

export default function payments(
  state = {
    modalPayments: false,
  },
  action
) {
  switch (action.type) {
    case ActionTypes.MODAL: {
      return {
        ...state,
        modalPayments: !state.modalPayments,
      };
    }

    case ActionTypes.PAYMENT: {
      return {
        ...state,
        modalPayments: false,
      };
    }

    default:
      return state;
  }
}
