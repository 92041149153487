import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { PageHeader } from '../../components/PageHeader';
import Confirmation from './Confirmation';

function Payments() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const [modalPayment, setModalPayment] = useState(false);
  const [payment, setPayment] = useState(0);
  const { modalPayments } = useSelector(state => state.payments);
  const { company } = useSelector(state => state.data);

  const handleClose = () => {
    dispatch({ type: '@payments/MODAL' });
  };

  const selectedPayment = selected => {
    setPayment(selected);
    setModalPayment(true);
  };

  return company ? (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modalPayments}
        className={classes.root}
      >
        <PageHeader handleClose={handleClose} title="formas de pagamento" />
        {company.recebimentoantecipado === 'N' && (
          <div className={classes.content}>
            {company.receberempix === 'S' && (
              <div className={classes.payments}>
                <span className={classes.title}>Pagar agora</span>
                <ul className={classes.list}>
                  <li>
                    <button
                      type="button"
                      className={classes.item}
                      onClick={() => selectedPayment(4)}
                    >
                      <div>
                        <AccountBalanceIcon fontSize="small" />
                        <span>pix</span>
                      </div>
                      <ChevronRightIcon fontSize="small" />
                    </button>
                  </li>
                </ul>
              </div>
            )}
            {(company.receberemdinheiro === 'S' ||
              company.receberemcredito === 'S' ||
              company.receberemdebito === 'S') && (
              <div className={classes.payments}>
                <span className={classes.title}>Pagar na entrega</span>
                <ul className={classes.list}>
                  {company.receberemdinheiro === 'S' && (
                    <li>
                      <button
                        type="button"
                        className={classes.item}
                        onClick={() => selectedPayment(1)}
                      >
                        <div>
                          <MonetizationOnIcon fontSize="small" />
                          <span>dinheiro</span>
                        </div>
                        <ChevronRightIcon fontSize="small" />
                      </button>
                    </li>
                  )}

                  {company.receberemcredito === 'S' && (
                    <li>
                      <button
                        type="button"
                        className={classes.item}
                        onClick={() => selectedPayment(2)}
                      >
                        <div>
                          <CreditCardIcon fontSize="small" />
                          <span>crédito</span>
                        </div>
                        <ChevronRightIcon fontSize="small" />
                      </button>
                    </li>
                  )}

                  {company.receberemdebito === 'S' && (
                    <li>
                      <button
                        type="button"
                        className={classes.item}
                        onClick={() => selectedPayment(3)}
                      >
                        <div>
                          <CreditCardIcon fontSize="small" />
                          <span>débito</span>
                        </div>
                        <ChevronRightIcon fontSize="small" />
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        )}

        {company.recebimentoantecipado === 'S' && (
          <div className={classes.content}>
            <div className={classes.payments}>
              <span className={classes.title}>Pagamento antecipado</span>
              <ul className={classes.list}>
                {company.receberempix === 'S' && (
                  <li>
                    <button
                      type="button"
                      className={classes.item}
                      onClick={() => selectedPayment(4)}
                    >
                      <div>
                        <AccountBalanceIcon fontSize="small" />
                        <span>pix</span>
                      </div>
                      <ChevronRightIcon fontSize="small" />
                    </button>
                  </li>
                )}
                {company.receberemcredito === 'S' && (
                  <li>
                    <button
                      type="button"
                      className={classes.item}
                      onClick={() => selectedPayment(2)}
                    >
                      <div>
                        <CreditCardIcon fontSize="small" />
                        <span>crédito</span>
                      </div>
                      <ChevronRightIcon fontSize="small" />
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </Dialog>

      <Confirmation
        modalPayment={modalPayment}
        setModalPayment={setModalPayment}
        payment={payment}
      />
    </>
  ) : null;
}

export default Payments;
