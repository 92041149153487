import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.25rem',
    gap: '1.5rem',
    textAlign: 'center',
    fontSize: '1rem',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    '& img': {
      width: 100,
    },
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '0.5rem',
    fontSize: '0.75rem',
    '& span:nth-child(2)': {
      color: '#f44336',
    },
  },
  button: {
    padding: '0.75rem',
  },
}));

export default useStyles;
