const types = Object.freeze({
  CREDENTIALS: '@store:credentials:1.0.0',
  ADDRESSES: '@store:addresses:1.0.0',
  ORDER: '@store:order:1.0.2',
  CLIENT: '@store:client:1.0.0',
  ORDERS: '@store:orders:1.0.2',
});

const defaultOrder = {
  items: [],
  subtotal: 0,
  delivery: 0,
  total: 0,
  modality: -1,
  payment: { id: 0, value: 0 },
  scheduling: false,
  day: '',
  hour: '',
  minutes: '',
};

const defaultClient = { name: '', phone: '' };

class Credentials {
  constructor() {
    if (Credentials.instance) {
      return Credentials.instance;
    }

    this.token = 'default';

    Credentials.instance = this;
    return this;
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }
}

export const setCredentials = credentials => {
  const { token } = credentials;
  const storage = new Credentials();
  storage.setToken(token);
};

export const getCredentials = () => {
  const storage = new Credentials();
  return { token: storage.getToken() };
};

export const setAdresses = addresses => {
  localStorage.setItem(types.ADDRESSES, JSON.stringify(addresses));
};

export const getAdresses = () => {
  const storage = localStorage.getItem(types.ADDRESSES);
  const addresses = storage ? JSON.parse(storage) : [];
  return addresses;
};

export const setOrder = order => {
  const data = order || defaultOrder;
  const { token } = getCredentials();
  localStorage.setItem(`${types.ORDER}:${token}`, JSON.stringify(data));
};

export const getOrder = () => {
  const { token } = getCredentials();
  const storage = localStorage.getItem(`${types.ORDER}:${token}`);
  const order = storage ? JSON.parse(storage) : defaultOrder;

  return order;
};

export const setClient = client => {
  localStorage.setItem(`${types.CLIENT}`, JSON.stringify(client));
};

export const getClient = () => {
  const storage = localStorage.getItem(`${types.CLIENT}`);
  const client = storage ? JSON.parse(storage) : defaultClient;

  return client;
};

export const setOrders = orders => {
  const { token } = getCredentials();
  localStorage.setItem(`${types.ORDERS}:${token}`, JSON.stringify(orders));
};

export const getOrders = () => {
  const { token } = getCredentials();
  const storage = localStorage.getItem(`${types.ORDERS}:${token}`);
  const orders = storage ? JSON.parse(storage) : [];
  return orders;
};
