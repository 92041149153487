import React from 'react';
import { OrderStatus } from '../../../../../../components/OrderStatus';
import { Container, Content, Info, Identifier } from './styles';

function Header({ company, background, order }) {
  const { id, date } = order;

  return (
    <Container>
      <Content>
        <img src={background} alt={company.name} />
        <Info>
          <span>{company.name}</span>
          <Identifier>
            <span># {id}</span>
            <span>{date}</span>
          </Identifier>
        </Info>
      </Content>
      <OrderStatus order={order} />
    </Container>
  );
}

export { Header };
