import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  background: #fafafa;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  width: 100%;
  height: 56px;
  background: #fafafa;

  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-bottom: 1px solid #e0e0e0;

  span {
    font-size: 0.8rem;
    color: #7f7f7f;
    text-transform: uppercase;
  }
`;

export const Historic = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0 0 88px 0;
  list-style: none;

  display: flex;
  flex-direction: column;
`;

export const Order = styled.li`
  width: 100%;
  border-top: 1px solid #e0e0e0;

  display: flex;
  flex-direction: column;
`;

export const OrderHeader = styled.button`
  width: 100%;
  padding: 1rem;
  background: none;
  border: none;
  border-bottom: 1px solid #e0e0e0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
  }

  span {
    flex: 1;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

export const OrderBody = styled.div`
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
