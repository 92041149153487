import React from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from './routes';

import store from './store';
import Menu from './components/Menu';
import Loading from './components/Loading';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const App = () => {
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    const key = process.env.REACT_APP_GOOLE_MAPS_KEY;

    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${key}`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  return (
    <Provider store={store}>
      <Menu />
      <CssBaseline />
      <Routes />
      <Loading />
    </Provider>
  );
};

export default App;
