import React from 'react';

import { Container } from './styles';

function Scheduling({ order }) {
  const { scheduling, day, hour, minutes } = order;
  let formattedDay = '';

  if (scheduling) {
    const splitDay = day.split('-');
    formattedDay = `${splitDay[2]}/${splitDay[1]}/${splitDay[0]}`;
  }

  return (
    scheduling && (
      <Container>
        <span>Agendamento</span>
        <span>{`${formattedDay} ${hour}:${minutes}`}</span>
      </Container>
    )
  );
}

export { Scheduling };
