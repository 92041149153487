import styled from 'styled-components';

export const Container = styled.button`
  padding: 1rem;
  background: #fff;
  border: 0;
  outline: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;

  &:hover {
    background: #eee;
  }

  span {
    font-size: 0.8rem;
    color: #ff1744;
  }
`;
