import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  img {
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
  }
`;

export const Info = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Identifier = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  span {
    font-size: 0.6rem;
    font-weight: 500;
    text-transform: uppercase;
  }
`;
