import React from 'react';

import splash from '../../assets/splash.png';

import { Container, Image } from './styles';

function Splash() {
  return (
    <Container>
      <Image src={splash} alt="splash" />
    </Container>
  );
}

export { Splash };
