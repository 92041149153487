import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
  },
  title: {
    marginBottom: '15px',
    fontWeight: 'bold',
  },
  modality: {
    listStyle: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    margin: '0px',
  },
}));

export default useStyles;
