import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import backgroundDefault from '../../assets/background.png';

import { Identifier } from './components/Identifier';
import { Items } from './components/Items';
import { ordersstatus } from '../../services/application';
import { Detail } from './components/Detail';
import { AddToCart } from '../../components/AddToCart';
import { OrderStatus } from '../../components/OrderStatus';

import {
  getOrders as ordersget,
  setOrders as ordersset,
} from '../../infra/config/storage';

import {
  Container,
  Header,
  Historic,
  Order,
  OrderHeader,
  OrderBody,
} from './styles';

function Orders() {
  const { company } = useSelector(state => state.data);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const background = company.background || backgroundDefault;

  const handleOrderClick = order => {
    setSelectedOrder(order);
  };

  useEffect(() => {
    const data = ordersget().filter(
      order =>
        order.pix === 'N' ||
        (order.pix === 'S' && order.pixsituacao === 'CONCLUIDA')
    );
    setOrders(data);
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const data = ordersget().filter(
        order =>
          order.pix === 'N' ||
          (order.pix === 'S' && order.pixsituacao === 'CONCLUIDA')
      );

      const ids = data
        .filter(
          order => order.status !== 'concluded' && order.status !== 'canceled'
        )
        .map(order => order.id);

      if (ids.length >= 1) {
        const status = await ordersstatus(ids);
        const updatedOrders = data.map(order => {
          const orderFound = status.find(item => item.id === order.id);
          return {
            ...order,
            status: orderFound ? orderFound.status : order.status,
          };
        });
        ordersset(updatedOrders);
        setOrders(updatedOrders);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Header>
        <span>meus pedidos</span>
      </Header>

      <Historic>
        {orders.map(order => (
          <Order key={order.id}>
            <OrderHeader type="button" onClick={() => handleOrderClick(order)}>
              <img src={background} alt={company.name} />
              <span>{company.name}</span>
              <ChevronRightIcon />
            </OrderHeader>
            <OrderBody>
              <Identifier order={order} />
              <OrderStatus order={order} />
              <Items order={order} />
            </OrderBody>
            <AddToCart order={order} />
          </Order>
        ))}
      </Historic>

      <Detail
        company={company}
        background={background}
        order={selectedOrder}
        onClose={() => setSelectedOrder(null)}
      />
    </Container>
  );
}

export { Orders };
