import { getAdresses, setAdresses } from '../../../infra/config/storage';
import { ActionTypes } from './actions';

export default function addresses(
  state = {
    modalAddresses: false,
    addresses: [],
  },
  action
) {
  switch (action.type) {
    case ActionTypes.LOAD: {
      if (state.addresses.length >= 1) return state;
      const data = getAdresses();
      return {
        ...state,
        addresses: [...data],
      };
    }

    case ActionTypes.MODAL: {
      return {
        ...state,
        modalAddresses: !state.modalAddresses,
      };
    }

    case ActionTypes.CREATE_SUCCESS: {
      const { address } = action.payload;
      const key = new Date().getTime();
      const data = [...state.addresses, { ...address, key }];
      setAdresses(data);

      return {
        ...state,
        addresses: data,
      };
    }

    default:
      return state;
  }
}
