import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { orderCreate } from '../../store/modules/order/actions';
import { addressesLoad } from '../../store/modules/addresses/actions';
import { dataLoad, dataLoading } from '../../store/modules/data/actions';
import { index } from '../../services/application';
import { setCredentials } from '../../infra/config/storage';

import { Splash } from '../../components/Splash';
import { AfterLoading } from './components/AfterLoading';

const tokenExists = process.env.REACT_APP_TOKEN;

function Store({ match }) {
  const { enqueueSnackbar } = useSnackbar();

  const { params } = match;
  const { token } = params;

  const dispatch = useDispatch();
  const { company: main } = useSelector(state => state.data);

  useEffect(() => {
    async function start() {
      const credentials = { token: tokenExists || token || 'default' };
      setCredentials(credentials);

      try {
        const response = await index();
        const { company, categories, kms, districts, cards } = response;
        dispatch(
          dataLoad({
            token,
            company,
            categories,
            kms,
            districts,
            cards,
          })
        );
        dispatch(addressesLoad());
        dispatch(orderCreate());
      } catch (error) {
        dispatch(dataLoading());

        enqueueSnackbar(
          'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
          { variant: 'error' }
        );
      }
    }

    start();
  }, [dispatch, token, enqueueSnackbar]);

  return main && main.name ? <AfterLoading /> : <Splash />;
}

export { Store };
