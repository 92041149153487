import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
    padding: '15px',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
  },
  title: {
    marginBottom: '15px',
    fontWeight: 'bold',
  },
  item: {
    padding: '10px 15px',
    borderRadius: '5px',
    border: '1px solid rgb(232,234,237)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'rgb(255, 255, 255) none repeat scroll 0% 0%',

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',

      '& > div': {
        minHeight: '48px',
        marginTop: '2px',
        marginLeft: '7.5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '& > span': {
          color: 'rgb(72, 84, 96)',
        },
      },
    },
  },
  button: {
    height: '56px',
    padding: '1rem',
    background: '#eee',
    color: '#000',
    border: '1px solid rgba(149, 149, 149, 0.8)',
    borderRadius: '.5rem',
    textAlign: 'center',
    cursor: 'pointer',
  },
}));

export default useStyles;
