import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import Header from '../../Header';
import Unavailability from '../../../../components/Unavailability';
import Body from '../../Body';
import Footer from '../../Footer';
import Detail from '../../../Detail';
import OrderButton from '../../../../components/OrderButton';
import Order from '../../../Order';
import Addresses from '../../../Addresses';
import Payments from '../../../Payments';
import Success from '../../../Success';
import { Pix } from '../../../Pix';

import { Container } from './styles';
import { TabPanel } from '../../../../components/TabPanel';
import { Orders } from '../../../Orders';

function AfterLoading() {
  const [tab, setTab] = useState('menu');
  const { company } = useSelector(state => state.data);
  const { order } = useSelector(state => state.order);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Container>
      <TabPanel value={tab} index="menu">
        <Header background={company.background} />
        <Unavailability />
        <Body />
        <Footer />
        {order && order.items && order.items.length >= 1 && <OrderButton />}
        <Detail />
        <Order />
        <Addresses />
        <Payments />
        <Success />
        <Pix />
      </TabPanel>
      <TabPanel value={tab} index="orders">
        <Orders />
      </TabPanel>
      <AppBar
        position="fixed"
        color="default"
        style={{ top: 'auto', bottom: 0 }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
        >
          <Tab value="menu" icon={<MenuIcon />} label="MENU" />
          <Tab
            value="orders"
            icon={<DescriptionOutlinedIcon />}
            label="PEDIDOS"
          />
        </Tabs>
      </AppBar>
    </Container>
  );
}

export { AfterLoading };
