import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 4rem;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const OrderHeader = styled.div`
  width: 100%;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;

  div:nth-child(1) {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    img {
      width: 3rem;
      height: 3rem;
      border-radius: 1.5rem;
    }
  }
`;
