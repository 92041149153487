import { ActionTypes } from './actions';

export default function item(
  state = {
    product: null,
    modalDetail: false,
    modalCombined: false,
  },
  action
) {
  switch (action.type) {
    case ActionTypes.SELECTED: {
      const { item: product } = action.payload;

      if (product) {
        return {
          ...state,
          product: {
            ...product,
            key: product.key || new Date().getTime(),
            amount: product.amount || 1,
            comment: product.comment || '',
            complements: product.complements.map(complement => {
              return {
                ...complement,
                selected: complement.selected || 0,
                items: complement.items.map(mitem => {
                  return {
                    ...mitem,
                    amount: mitem.amount || 0,
                  };
                }),
              };
            }),
          },
          modalDetail: true,
        };
      }

      return state;
    }

    case ActionTypes.COMPLEMENT_ADD: {
      const { item: product } = action.payload;

      if (product) {
        const comple = state.product.complements.find(
          f => f.complement_id === product.complement_id
        );

        if (comple.selected === comple.max) return state;

        return {
          ...state,
          product: {
            ...state.product,
            complements: state.product.complements.map(complement => {
              return {
                ...complement,
                selected:
                  complement.complement_id === product.complement_id
                    ? complement.selected + 1
                    : complement.selected,
                items: complement.items.map(mitem => {
                  return {
                    ...mitem,
                    amount:
                      mitem.item_id === product.item_id
                        ? mitem.amount + 1
                        : mitem.amount,
                  };
                }),
              };
            }),
          },
        };
      }

      return state;
    }

    case ActionTypes.COMPLEMENT_REMOVE: {
      const { item: product } = action.payload;

      if (product) {
        const comple = state.product.complements.find(
          f => f.complement_id === product.complement_id
        );

        if (comple.selected <= 0) return state;

        return {
          ...state,
          product: {
            ...state.product,
            complements: state.product.complements.map(complement => {
              return {
                ...complement,
                selected:
                  complement.complement_id === product.complement_id
                    ? complement.selected - 1
                    : complement.selected,
                items: complement.items.map(mitem => {
                  return {
                    ...mitem,
                    amount:
                      mitem.item_id === product.item_id
                        ? mitem.amount - 1
                        : mitem.amount,
                  };
                }),
              };
            }),
          },
        };
      }

      return state;
    }

    case ActionTypes.CANCEL: {
      return {
        product: null,
        modalDetail: false,
        modalCombined: false,
      };
    }

    default:
      return state;
  }
}
