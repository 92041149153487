import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 500;
    color: #666;
  }

  span:last-child {
    font-size: 0.8rem;
    text-transform: uppercase;
  }
`;
