import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  span {
    font-size: 0.6rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  span:nth-child(2) {
    text-transform: lowercase;
  }

  /* span:first-child {
    text-transform: capitalize;
  } */
`;
