import React from 'react';

import { Address, Container } from './styles';

function Delivered({ order }) {
  const { address } = order;

  return (
    address && (
      <Container>
        <span>Pedido entregue em</span>
        <Address>
          <span>{address.name}</span>
          <span>{`${address.public_place}, ${address.number}`}</span>
          <span>{`${address.neighborhood} - ${address.city} - ${address.state}`}</span>
        </Address>
      </Container>
    )
  );
}

export { Delivered };
